import momo from "../../../img/momo.png";
import bank from "../../../img/atm.png";
import card from "../../../img/card.png";
import React, { useEffect, useState } from 'react';
import Notiflix from "notiflix";
import { Link } from "react-router-dom";
import { CustomAxios } from "../../../Service/CustomAxios";
import { Config } from "../../../Config";
import LoadingImg from "../../../img/loading.gif";

const RechargeCard = () => {

    const title = 'Nạp PCoin';
    useEffect(() => {
        document.title = title;
    }, [title]);

    const [buttonTextCard, setButtonText] = useState('Xác nhận');
    const [sortedColumn, setSortedColumn] = useState(null);
    const [sortDirection, setSortDirection] = useState('desc'); // 'asc' or 'desc'
    const [currentPage, setCurrentPage] = useState(1);
    const [buttonSendCard, setbuttonSendCard] = useState(false);
    const [inputSend, setinputSend] = useState(false);
    const [cardget, setcardget] = useState(null);
    const access_token = localStorage.getItem("access_token");
    const [history, sethistory] = useState(null);
    const formatNumber = (number, options) => {
        return new Intl.NumberFormat('vi-VN', options).format(number);
    };
    const formatInt = (e) => {
        const formattedAmount = new Intl.NumberFormat();
        return formattedAmount.format(e);
    };
    const SendCard = async (e) => {
        e.preventDefault();
        setButtonText('Đang xử lí...');
        setbuttonSendCard(true);
        setinputSend(true);

        const form = document.forms[0];
        const { provider_id, amount, code, serial } = form;

        if (provider_id.value === '' || amount.value === '' || code.value === '' || serial.value === '') {
            Notiflix.Report.failure('Thất bại', 'Không được để trống!', 'Xác nhận', () => {
                setinputSend(false);
                setbuttonSendCard(false);
                setButtonText('Xác nhận');
            });

        } else {

            try {
                const response = await CustomAxios(Config.cardstore, {
                    provider_id: provider_id.value,
                    amount: amount.value,
                    code: code.value,
                    serial: serial.value,
                }, {
                    Authorization: `Bearer ${access_token}`,
                    "Content-Type": 'application/json'
                }, 'POST');
                if (response.status === 200) {

                    if (response.data.status === true) {
                        Notiflix.Report.success('Thông báo', response.data.message, 'Xác nhận');
                    } else {
                        Notiflix.Report.failure('Thất bại', response.data.message, 'Xác nhận', () => {
                            setinputSend(false);
                            setbuttonSendCard(false);
                            setButtonText('Xác nhận');
                        });
                    }
                }
            } catch (error) {
                localStorage.clear();
                window.location.href = "/home";
            }
        }
    };

    const fetchDataTelco = async () => {

        try {
            const response = await CustomAxios(Config.cardsetting, {
            }, {
                Authorization: `Bearer ${access_token}`,
                "Content-Type": 'application/json'
            }, 'POST');
            if (response.status === 200) {
                if (response.data.status === true) {
                    setcardget(response.data);
                }
            }
        } catch (error) {
            localStorage.clear();
            window.location.href = "/home";
        }
    };
    const History = async () => {
        try {
            const response = await CustomAxios(Config.cardhistory, {
            }, {
                Authorization: `Bearer ${access_token}`,
                "Content-Type": 'application/json'
            }, 'POST');
            if (response.status === 200) {
                if (response.data.status === true) {
                    sethistory(response.data);
                }
            }
        } catch (error) {
            localStorage.clear();
            window.location.href = "/home";
        }
    };

    useEffect(() => {
        if (access_token) {
            fetchDataTelco();
            History();
        }
    }, [access_token]);

    const providersList = cardget?.providers.map((provider, index) => (
        <option key={index} value={provider.key_tel}>
            {provider.name}
        </option>
    ));
    const denominationsList = cardget?.denominations.map(
        (denominations, index) => (
            <option key={index} value={denominations}>
                {formatInt(denominations)}đ - Nhận{" "}
                {formatInt(
                    denominations - (denominations * cardget.card_fee) / 100
                )}
                đ
            </option>
        )
    );

    const getStatusBadge = (row) => {
        switch (row.status) {
            case "99":
                return <span className="badge badge-pill badge-warning text-center">Thẻ chờ</span>;
            case "1":
                return <span className="badge badge-pill badge-success text-center">Thẻ đúng</span>;
            default:
                return <span className="badge badge-pill badge-danger text-center">Thẻ sai</span>;
        }
    };
    const handleSort = (column) => {
        if (sortedColumn === column) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortedColumn(column);
            setSortDirection('asc');
        }
    };

    // Calculate the index range for the current page
    const startIndex = (currentPage - 1) * 5;
    const endIndex = startIndex + 5;
    const sortedData = [];
    if (history > 0) {
        sortedData += history.slice().sort((a, b) => {
            // Customize the sorting logic based on the data type and column
            switch (sortedColumn) {
                case 'ID':
                    return (sortDirection === 'asc' ? a.id - b.id : b.id - a.id);
                case 'Nhà mạng':
                    return (sortDirection === 'asc' ? a.telco - b.telco : b.telco - a.telco);
                case 'Mệnh giá':
                    return (sortDirection === 'asc' ? a.declared_value - b.declared_value : b.declared_value - a.declared_value);
                case 'Nhận được':
                    return (sortDirection === 'asc' ? a.real_value - b.real_value : b.real_value - a.real_value);
                // Add more cases for other columns as needed
                default:
                    return '0';
            }
        });
    }

    const paginatedData = sortedData.slice(startIndex, endIndex);
    const totalPages = Math.ceil(sortedData.length / 5);
    const handlePageChange = (pageNumber, totalNumber) => {
        if (pageNumber > 0 && pageNumber <= totalNumber) {
            setCurrentPage(pageNumber);
        }
    };

    return (
        <div className="card">
            <div className="card-body">
                <div>
                    <div className="fs-5 fw-semibold text-center">Chọn hình thức nạp</div>
                    <div className="row text-center justify-content-center row-cols-2 row-cols-lg-5 g-2 g-lg-2 my-1 mb-2">
                        <div className="col"><Link className="w-100 fw-semibold" to="/recharge/momo">
                            <div className="recharge-method-item false"><img alt="method" src={momo}
                                data-pin-no-hover="true" /></div>
                        </Link>
                        </div>
                        <div className="col"><Link className="w-100 fw-semibold" to="/recharge/bank">
                            <div className="recharge-method-item false"><img alt="method" src={bank}
                                data-pin-no-hover="true" /></div>
                        </Link>
                        </div>
                        <div className="col"><Link className="w-100 fw-semibold" to="/recharge/card">
                            <div className="recharge-method-item active"><img alt="method" src={card}
                                data-pin-no-hover="true" /></div>
                        </Link>
                        </div>
                    </div>
                </div>
                <div>

                    <div className="w-100 d-flex justify-content-center">
                        <form action="#" className="pb-3" style={{ width: '26rem' }} onSubmit={SendCard}>
                            <div className="fw-semibold fs-5 my-3 text-center">Nạp thẻ điện thoại</div>
                            <div className="fw-semibold my-3 text-center"><span className="text-danger">{cardget?.svip.alert ?? null}</span></div>
                            {/* <div className="fw-semibold my-3 text-center"><span className="text-danger">{userLogin?.svipAlert ?? null}</span></div> */}
                            {
                                cardget === null ?
                                    <>
                                        <div className="text-center"><img style={{ maxWidth: "75px" }} src={LoadingImg}></img></div>
                                    </> :
                                    <>
                                        <div className="mb-2"><label className="fw-semibold">Nhà mạng</label>
                                            <select required disabled={inputSend ? true : false} name="provider_id" type="text" className="form-control form-control-solid">
                                                {/* <option value="">Chọn nhà mạng</option> */}
                                                {providersList}
                                            </select>
                                        </div>
                                        <div className="mb-2"><label className="fw-semibold">Mệnh giá</label>
                                            <select required disabled={inputSend ? true : false} name="amount" type="text" className="form-control form-control-solid">
                                                {/* <option value="">Chọn mệnh giá</option> */}
                                                {denominationsList}
                                            </select>
                                        </div>
                                        <div className="mb-2"><label className="fw-semibold">Mã thẻ</label>
                                            <div className="input-group"><input required disabled={inputSend ? true : false} name="code" type="text" autoComplete="off" placeholder="Nhập mã thẻ"
                                                className="form-control form-control-solid" /></div>
                                        </div>
                                        <div className="mb-2"><label className="fw-semibold">Mã serial</label>
                                            <div className="input-group"><input required disabled={inputSend ? true : false} name="serial" type="text" autoComplete="off"
                                                placeholder="Nhập mã serial" className="form-control form-control-solid" /></div>
                                        </div>
                                        <div className="text-center mt-3"><button type="submit" className="me-3 px-3 btn btn-primary" disabled={buttonSendCard ? true : false}>{buttonTextCard}</button>
                                        </div>
                                    </>
                            }
                        </form>
                    </div>

                    <div className="table-responsive">

                        <table className="table align-middle table-row-dashed gy-5 dataTable no-footer">
                            <thead>
                                <tr>
                                    <th onClick={() => handleSort('ID')}>ID</th>
                                    <th onClick={() => handleSort('Nhà mạng')}>Mã Serial</th>
                                    <th onClick={() => handleSort('Mệnh giá')}>Mệnh giá</th>
                                    <th onClick={() => handleSort('Nhận được')}>Nhận được</th>
                                    <th onClick={() => handleSort('Trạng thái')}>Trạng thái</th>
                                    <th onClick={() => handleSort('Ngày tạo')}>Ngày tạo</th>
                                </tr>
                            </thead>
                            <tbody className="fw-semibold">
                                {history ? (
                                    <>
                                        {paginatedData.map((row) => (
                                            <tr key={row.id}>
                                                <td>{row.id}</td>
                                                <td>{row.telco}</td>
                                                <td><span className="text-success fw-semibold">{formatNumber(row.declared_value)}đ</span></td>
                                                <td><span className="text-danger fw-semibold">{formatNumber(row.real_value)}đ</span></td>
                                                <td>{getStatusBadge(row)}</td>
                                                <td>{row.created_at}</td>
                                            </tr>
                                        ))}
                                    </>
                                ) : (
                                    <tr>
                                        <td colSpan="6"><div className="d-flex text-center w-100 align-content-center justify-content-center" bis_skin_checked="1">Không có bản ghi nào</div></td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div className="row">
                        <span>Page {currentPage} of {totalPages}</span>
                        <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start"></div>
                        <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
                            <div>
                                <ul className="pagination">
                                    <li className="page-item"><a className="page-link" style={{ cursor: "pointer" }} onClick={() => handlePageChange(currentPage - 1, totalPages)} disabled={currentPage === 1}>&lt;</a></li>
                                    <li className="page-item active"><a className="page-link" style={{ cursor: "pointer" }}>1</a></li>
                                    <li className="page-item"><a className="page-link" style={{ cursor: "pointer" }} onClick={() => handlePageChange(currentPage + 1, totalPages)} disabled={currentPage === totalPages}>&gt;</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}
export default RechargeCard;